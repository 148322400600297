import './styles/video.css'
import "./styles/media.css"

import { DefaultPlayer as VideoPlayer } from 'react-html5video';
import 'react-html5video/dist/styles.css'
import { urlState } from '../../utils/atoms'
import { useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil'
import useVideo from '../../utils/hooks/useVideo';
import { PlayBtn } from '../../assets/svg';
import { useState } from 'react';


const VideoBox = () => {
    const [videos] = useVideo()
    const [url] = useRecoilState(urlState)
    const [showPlay, setshowPlay] = useState(true)
    const videoRef = useRef(null);
    var vid = document.getElementById("videoPlayer")



    const renderVideo = useMemo(() => {
        return videos && videos.map((elem, ind) => {
            return (
                <div className="videoBox" key={ind} >

                    <VideoPlayer loop>
                        <source src={url + elem.video} type="video/webm" />
                    </VideoPlayer>

                    {/* <video src={url + elem.video} width="100%" muted ref={videoRef}></video> */}
                </div>
            )
        })
    }, [videos])
    return (
        <div id='videoReel'>
            <div className="container videoSection" >
                {/* {showPlay ? <PlayBtn /> : null} */}
                {renderVideo}

            </div>
        </div>
    )
}

export default VideoBox