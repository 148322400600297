import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
import {RecoilRoot} from 'recoil'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllProjects from './components/AllProjects/AllProjects';
import ProjectDesc from './components/ProjectDesc/ProjectDesc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RecoilRoot>  
      <BrowserRouter>
        <Routes>
          <Route index path={'/'} element={<App/>}></Route>  
          <Route path={'all'} element={<AllProjects/>}/>
          <Route path={'project'} element={<ProjectDesc/>}/>
        </Routes>
       </BrowserRouter>
        </RecoilRoot>
  </React.StrictMode>
  
);



