import { useState } from 'react';
import './styles/contacts.css'
import "./styles/media.css"
import arrow from "../../assets/img/arrow.svg"
import LetsTalk from '../LetsTalk/LetsTalk';
const Contacts = () => {
	
	const [isLetsTalk, setLetsTalk] = useState(false);
	const LetsTalkPopup = () => {
	  setLetsTalk(!isLetsTalk);
	}

	return (
		<div id='contacts'>
			<div className="aboutSection mt150">
				<div className="container">
					<h3>ADDRESS</h3>
				</div>
			</div>

			<div className="container">
				<div className="wrapper contactWrap">
					<div className="contactBox">
						<h6>UAE Address</h6>
						<p className='addressText'>UAE, DUBAI, SHAIKH ZAYED RD</p>
						<p>Office No : 6, P.o box : 9070</p>
					 
					</div>
					<div className="contactBox">
						<h6>PHONE NUMBER</h6>
						<a href="tel: +971508012203" className='addressText'>Tel:  +971  50  801  2203</a>
						<a href="tel: +971581191977" className='mt5'>Tel:  +971  58  119  1977</a>	
					</div>
					<div className="contactBox">
						<h6>E-MAIL ADDRESSES</h6>
						<a className='addressText' href="mailto: info@techman-pro.com">info@techman-pro.com</a>
					</div>					
				</div>
				<div className="wrapper contactWrap">
					<div className="contactBox">					 
						<h6 className=''>Saudi Arabia Address</h6>
						<p className='addressText'>RIYADH, SAUDI ARABIA</p>						
					</div>
					<div className="contactBox">
						<h6>PHONE NUMBER</h6>
						<a href="tel: +966536191250" className='mt5'>Tel:  +966  53  619  1250</a>	
					</div>
					<div className="contactBox">
					</div>					
				</div>
				{isLetsTalk && <LetsTalk handleClose={LetsTalkPopup} />}
				<button className="talkBtn" onClick={LetsTalkPopup}> Let's Talk<span><img src={arrow} /></span></button>
				
			</div>
			
		</div>
	)
}

export default Contacts