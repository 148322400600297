import './style.css'

import { useMemo } from 'react';
import { useRecoilState } from 'recoil'
import useVideo from '../../../utils/hooks/useVideo';
import { urlState } from '../../../utils/atoms';
import { DefaultPlayer as VideoPlayer } from 'react-html5video';
import 'react-html5video/dist/styles.css'

const Video = (props) => {

    const [videos] = useVideo()
    const [url] = useRecoilState(urlState)



    const renderVideo = useMemo(() => {
        return videos && videos.map((elem, ind) => {
            return (
                <div key={ind} >
                    <VideoPlayer autoPlay loop>
                        <source src={url + elem.video}  type="video/webm"/>
                    </VideoPlayer>
                  
                </div>
            )
        })
    }, [videos])

    return (
        <div className='videoModal'>
            <div className="videoPopBox">

                <div className="videoCard">
                    {renderVideo}
                    <button className='closeBtn' onClick={props.handleClose}>x</button>
                </div>
            </div>
        </div>
    )
}

export default Video