import './styles/who.css'
import "./styles/media.css"

const Who = () => {
   
    return (
        <div id='who'>
            <div className="aboutSection" >
                <div className="container">
                    <h3>WHO WE ARE</h3>
                </div>
            </div>
            <div className="container">
                <div className="wrapper whoWrap">
                    <div className="rightBoxText pNone">
                        <p id='firstParagraph' className="p40">TECHMAN PRO is the event production, multimedia show company with headquarter in Dubai, offices in Saudi Arabia and Turkmenistan. For over more than decades, we have been consistently delivering industry successful projects, from government organizations to corporate companies.</p>
                        <div className="hr"></div>
                        <p id='secondParagraph' className="p40">We blend multidisciplinary talents in our firm of highly experienced production engineers, content creators, project managers and technicians, to assist and execute any event from pre to post production and ensures it is perfectly memorable and audience appreciable.</p>
                    </div>
                  
                </div>
            </div>
            <div className="hr"></div>
        </div>
    )
}

export default Who