import "./styles/service.css"
import "./styles/media.css"
import Service1 from "../../assets/img/LASER SHOWS.jpg"
import Service2 from "../../assets/img/MUSICAL FOUNTAINS.jpg"
import Service3 from "../../assets/img/VIDEO PROJECTION.jpg"
import Service4 from "../../assets/img/LED SCREEN.jpg"
import Service5 from "../../assets/img/TRUSSING SYSTEM.jpg"
import Service6 from "../../assets/img/PRO LIGHTING SYSTEM.jpg"
import Service7 from "../../assets/img/SPECIAL EFFECTS.JPG"
import Service8 from "../../assets/img/PRO AUDIO.jpg"

const Service = () => {
    
    return (
        <div id="services">
            <div className="aboutSection mt150">
                <div className="container">
                    <h3>Services</h3>
                </div>
            </div>
            <div className="servicesSection">
                <div className="container mb-85">
                    <div className="wrapper wrap serviceWrap">                    
                        <div className="serviceBox ">  
                                         
                            <h6 className="mt20"><span>LASER</span><br/> SHOWS</h6>
                           <img src={Service1} alt="" />
                        </div>
                        <div className="serviceBox">                           
                            <h6 className="mt20"><span>MUSICAL</span><br/> FOUNTAINS</h6>
                            <img src={Service2} alt="" />
                        </div>
                        <div className="serviceBox">                           
                            <h6 className="mt20"><span>VIDEO</span><br/>PROJECTION</h6>
                            <img src={Service3} alt="" />
                        </div>
                        <div className="serviceBox">                            
                            <h6 className="mt20"><span>LED</span><br/> SCREEN</h6>
                            <img src={Service4} alt="" />
                        </div>
                        <div className="serviceBox">                            
                            <h6 className="mt20"><span>TRUSSING</span><br/> SYSTEM</h6>
                            <img src={Service5} alt="" />
                        </div>
                        <div className="serviceBox">                            
                            <h6 className="mt20"><span>PRO LIGHTING</span><br/>SYSTEM</h6>
                            <img src={Service6} alt="" />
                        </div>
                        <div className="serviceBox">                            
                            <h6 className="mt20"><span>SPECIAL</span><br/> EFFECTS</h6>
                            <img src={Service7} alt="" />
                        </div>
                        <div className="serviceBox">                            
                            <h6 className="mt20"><span>PRO</span><br/> AUDIO</h6>
                            <img src={Service8} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service