export const Insta = () => {
    return(
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="30px" height="30px"><path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"/></svg>
    )
}

export const YouTube = () => {
    return(
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="30px" height="30px"><path d="M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z"/></svg>
    )
}

export const Facebook = () => {
    return(
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="30px" height="30px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 26.580078 10 C 23.92822 10 21.917076 10.867217 20.677734 12.400391 C 19.438393 13.933564 19 15.981046 19 18.226562 L 19 20 L 17 20 A 1.0001 1.0001 0 0 0 16 21 L 16 26 A 1.0001 1.0001 0 0 0 17 27 L 19 27 L 19 39 A 1.0001 1.0001 0 0 0 20 40 L 26 40 A 1.0001 1.0001 0 0 0 27 39 L 27 27 L 31 27 A 1.0001 1.0001 0 0 0 31.980469 26.195312 L 32.980469 21.195312 A 1.0001 1.0001 0 0 0 32 20 L 27 20 L 27 17.806641 C 27 17.321617 27.03137 17.325614 27.171875 17.234375 C 27.312385 17.143136 27.820197 17 28.710938 17 L 32 17 A 1.0001 1.0001 0 0 0 33 16 L 33 12 A 1.0001 1.0001 0 0 0 32.335938 11.058594 C 32.335938 11.058594 29.456337 10 26.580078 10 z M 26.580078 12 C 28.472499 12 30.227501 12.510447 31 12.751953 L 31 15 L 28.710938 15 C 27.663677 15 26.813974 15.08458 26.083984 15.558594 C 25.353995 16.032605 25 16.940664 25 17.806641 L 25 21 A 1.0001 1.0001 0 0 0 26 22 L 30.779297 22 L 30.179688 25 L 26 25 A 1.0001 1.0001 0 0 0 25 26 L 25 38 L 21 38 L 21 26 A 1.0001 1.0001 0 0 0 20 25 L 18 25 L 18 22 L 20 22 A 1.0001 1.0001 0 0 0 21 21 L 21 18.226562 C 21 16.24708 21.405014 14.681779 22.232422 13.658203 C 23.05983 12.634627 24.336936 12 26.580078 12 z"/></svg>
    )
}

export const Scroll = () => {
    return(
        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.16699 14.6667L7.00033 20.7778M7.00033 20.7778L7.00033 1.22227M7.00033 20.7778L12.8337 14.6667" stroke="#D9D9D9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}


export const PlayBtn = () => {
    return(
        <svg className="playBtn" id="videoPlayer" width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 44C2 53.0129 2.48554 60.1189 3.73196 65.7273C4.97333 71.313 6.94268 75.2898 9.82646 78.1735C12.7102 81.0573 16.687 83.0267 22.2727 84.268C27.8811 85.5145 34.9871 86 44 86C53.0129 86 60.1189 85.5145 65.7273 84.268C71.313 83.0267 75.2898 81.0573 78.1735 78.1735C81.0573 75.2898 83.0267 71.313 84.268 65.7273C85.5145 60.1189 86 53.0129 86 44C86 34.9871 85.5145 27.8811 84.268 22.2727C83.0267 16.687 81.0573 12.7102 78.1735 9.82646C75.2898 6.94268 71.313 4.97334 65.7273 3.73196C60.1189 2.48554 53.0129 2 44 2C34.9871 2 27.8811 2.48554 22.2727 3.73196C16.687 4.97334 12.7102 6.94268 9.82646 9.82646C6.94268 12.7102 4.97333 16.687 3.73196 22.2727C2.48554 27.8811 2 34.9871 2 44Z" stroke="#D9D9D9" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60.276 44.0002C60.276 41.9211 59.0635 40.4201 57.5774 39.1893C56.1448 38.0027 54.0644 36.7496 51.5547 35.2379L51.4106 35.1511L45.4875 31.5831L45.3399 31.4942C42.8333 29.9841 40.7521 28.7304 39.0478 28.0279C37.2914 27.304 35.364 26.9235 33.5517 28.0152C31.7713 29.0877 31.1477 30.957 30.8761 32.8659C30.6092 34.7416 30.6093 37.2365 30.6094 40.2696L30.6094 40.4322L30.6094 47.5681L30.6094 47.7307C30.6093 50.7639 30.6092 53.2587 30.8761 55.1344C31.1477 57.0433 31.7713 58.9127 33.5517 59.9851C35.364 61.0768 37.2914 60.6963 39.0478 59.9724C40.7521 59.2699 42.8333 58.0162 45.3399 56.5061L45.4875 56.4172L51.4106 52.8492L51.5547 52.7624C54.0645 51.2507 56.1448 49.9976 57.5774 48.8111C59.0635 47.5802 60.276 46.0792 60.276 44.0002Z" stroke="#D9D9D9" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}


