import { useEffect, useState } from 'react'
import { getOneProject } from '../api'

const useOneProject = () => {
  
    const [project, setOneProject] = useState()

    const getData = async(setFunc) => {
        const response = await getOneProject()

        setFunc(response.data)
        // console.log(response.data)
    }

    useEffect(()=>{
        getData(setOneProject)
        // console.log(projects)
    }, [])

    return [project]
}

export default useOneProject