import React, { forwardRef, useImperativeHandle, useState } from 'react'
import "./snckbar.css"
const Snackbar = forwardRef((props,ref) => {
  var checkmark = '✔';
  var crossmark = '✗';
  useImperativeHandle(ref, () => 
  ({
    show() {
      setShowSnackbar(true)
      setTimeout(() =>{
        setShowSnackbar(false)
      }, 3000)
    }
  }))
  const [showSnackbar, setShowSnackbar] = useState(false)
  return (
    <div className='snackbar' id={showSnackbar ? "show" : "hide"} style={{backgroundColor: props.type ==="success" ? "#2a2a2a" : "#2a2a2a" , color:  props.type ==="success" ? "#00F593" : "#FF0033"}}>
      <div className='symbol'>
        {props.type === 'success' ? <h3 style={{color: "#fff"}}>{checkmark}</h3> : <h3>{crossmark}</h3>}
      </div>
      <div className='message'>{props.message}</div>
    </div>
  )
})

export default Snackbar