import { useRecoilState } from 'recoil';
import { urlState } from '../../utils/atoms';
import useOneProject from '../../utils/hooks/useOnePoject';
import Footer from '../Footer/Footer'
import Nav from '../Navbar/Nav'
import './style.css'
import './media.css'
import { DefaultPlayer as VideoPlayer } from 'react-html5video';
import 'react-html5video/dist/styles.css'

const ProjectDesc = () => {

    const [url] = useRecoilState(urlState)
    const [project] = useOneProject();

    return (
        <div>
            <Nav />
            <div className='project_container pb150'>
                <div className='project_description'>
                    <h6><span>{project && project.title_highlight}</span> {project && project.title_head}</h6>
                    <p>{project && project.description}</p>
                </div>

                <div className="show_gallery">
                    {project && project.project.project_images.map(function (object, i) {
                        return (
                            <img className="fea" src={project && url + object.image} />
                        )
                    })}

                    {project && project.project.project_videos.map(function (object, i) {
                        return (
                            <VideoPlayer loop>
                                <source src={project && url + object.video} muted type="video/webm" key={i} />
                            </VideoPlayer>

                        )
                    })}
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ProjectDesc