import { useEffect, useState } from 'react'
import { getProjects } from '../api'

const useProject = () => {
  
    const [projects, setProjects] = useState()

    const getData = async(setFunc) => {
        const response = await getProjects()

        setFunc(response.data)
        // console.log(response.data)
    }

    useEffect(()=>{
        getData(setProjects)
        // console.log(projects)
    }, [])

    return [projects]
}

export default useProject