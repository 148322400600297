import './styles/media.css'
import './styles/nav.css'
import Burger from './Burger'
import logo from "../../assets/img/logo.png"

import { useState, useEffect } from 'react'
const Nav = () => {
    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 80)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroil', handleScroll)
    }, [])

    return (
        <div className={`container nav ${sticky ? "sticky" : ""}`}>
            <div className="logoBox">
                <a href="/">
                    <img src={logo} /></a>
            </div>
            <div className="infoBox">
                <div className='navLink'>
                    <a href="/">Home</a>
                    <a href="/#who">Who we are</a>
                    <a href="/#services">Service</a>
                    <a href="all">Projects</a>
                    <a href="/#contacts">Contacts</a>
                </div>

                <Burger />
            </div>
        </div>
    )
}

export default Nav