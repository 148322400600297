import React, { useState, useRef } from 'react'
import { postEmail } from '../../utils/api';
import Snackbar from './Snackbar/snackbar';
import './letstalk.css'
const LetsTalk = (props) => {


    const [userName, setUserName] = useState();
    const [userEmail, setUserEmail] = useState();
    const [userNumber, setUserNumber] = useState();
    const [userText, setUserText] = useState();
    const snackbarRef = useRef(null)
    
    const SnackbarType = {
        success: "success",
        fail: 'fail',
    }

    const handleSend = async () => {
        console.log(userName);
        try {
            await postEmail(new URLSearchParams({
                full_name: userName,
                phone: userNumber,
                email: userEmail,
                message: userText

            }))
            snackbarRef.current.show()
            window.location.reload()
     }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='letsTalkModal'>
            <div className="letsTalkBox">
                <div className="modal-header">
                    <h2>Let's Talk!</h2>
                </div>
                <div className="letsTalkCard">
                    <form onSubmit={(e) => { e.preventDefault() }}>
                        <input type="text" placeholder='Name' required onChange={(e) => setUserName(e.target.value)} />
                        <input type="tel" placeholder='Number' required onChange={(e) => setUserNumber(e.target.value)} />
                        <input type="email" placeholder='E-mail' required onChange={(e) => setUserEmail(e.target.value)} />
                        <textarea placeholder='Your message..' required onChange={(e) => setUserText(e.target.value)}></textarea>
                        <div className='buttonGroup'>
                            <button className='closeBtn' onClick={props.handleClose}>x</button>
                            <button className='sendBtn' type='submit' onClick={handleSend}>Send</button>
                        </div>
                        <Snackbar ref={snackbarRef} message="Message has been sent!!" type={SnackbarType.success} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LetsTalk