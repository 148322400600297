import './styles/projects.css'
import './styles/gallery.css'
import "./styles/media.css"
import useProjects from '../../utils/hooks/useProject'
import { urlState } from '../../utils/atoms'
import { useMemo } from 'react';
import { useRecoilState } from 'recoil'
import { useNavigate } from "react-router-dom";




const Project = () => {

    const [projects] = useProjects()
    const [url] = useRecoilState(urlState)
    const navigate = useNavigate();

    const renderProj = useMemo(() => {
        return projects && projects.slice(0, 3).map((elem, ind) => {

            return (
                <div className='projectBox'>
                    <div className="item" style={{ backgroundImage: `url(${url + elem.project.multimedia})` }} key={ind} >
                    </div>
                    <h6 className="mt20"><span>{elem.title_highlight}</span><br /> {elem.title_head}</h6>
                </div>
            )
        })
    }, [projects])


    return (
        <div id='projects'>
            <div className='portfolioSection'>
                <div className="sectionBorder">
                    <div className="container ">
                        <h3>PROJECTS</h3>
                    </div>
                </div>
                <div className="container projectGrid mb50">
                    {renderProj}
                </div>
                <div className="container mt150 mb-110">
                    <button className='showAllBtn' onClick={() => navigate("all")}>Show All Projects</button>
                </div>
            </div>


        </div>

    )
}

export default Project
