import Nav from "./components/Navbar/Nav";
import Home from "./components/Home/Home";
import Who from "./components/Who/Who";
import Service from "./components/Services/Service";
import Projects from "./components/Project/Project";
import VideoBox from "./components/VideoBox/VideoBox";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";




function App() {

  return (
    <div className="App">
     <Nav />
     <Home />
     <VideoBox/>
     <Who/>
     <Service/>
     <Projects/> 
     <Contacts/>
     <Footer/>
    </div>
  );
}

export default App;
