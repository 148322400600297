import { NavLink } from "react-router-dom";
import React, { useRef, useState } from 'react';
import './styles/nav.css'



const Burger = () => {
    
    const burgerRef = useRef();
    const navRef = useRef();
    const [activeState, setActiveState] = useState(false);
    const [showState, setShowState] = useState(true);
    const handleClick = () => {
        burgerRef.current.classList.toggle('activeBurger');
        navRef.current.classList.toggle("translate")
    }



    return (
        <React.Fragment>          
            <div ref={burgerRef} className={`hamburger ${activeState ? 'activeBurger' : ''}`} onClick={handleClick}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <nav ref={navRef} className={` ${showState ? 'translate' : ''}`}>
                <li><a href='/' onClick={handleClick} >Home</a> </li>
                <li><a href='/#who'  onClick={handleClick}  >Who we are</a> </li>
                <li><a href='/#services'  onClick={handleClick} >Services</a> </li>
                <li><a href='/all'  onClick={handleClick} >Projects</a> </li>
                <li><a href='/#contacts' onClick={handleClick} >Contacts</a> </li>

              
            </nav>
        </React.Fragment>
    )
}

export default Burger