import React from 'react'
import Nav from '../Navbar/Nav'
import useProjects from '../../utils/hooks/useProject'
import { urlState } from '../../utils/atoms'
import { useMemo } from 'react';
import { useRecoilState } from 'recoil'
import { useNavigate } from "react-router-dom";
import Footer from '../Footer/Footer';

const AllProjects = () => {
    const [projects] = useProjects()
    const [url] = useRecoilState(urlState)
    const navigate = useNavigate();
    const setProjId = (id) => {
        localStorage.setItem('one_id', id)
    }
    const Users = useMemo(() => {

        return projects && projects.map((user, i) => {

            return (
                <div className='projectBpx mb-110'>
                    <div key={i} className="item " style={{ backgroundImage: `url(${url + user.project.multimedia})` }} onClick={() => {
                        navigate("/project");
                        setProjId(user.project.id)
                    }}>                       
                    </div>
                    <h6 className="mt20"><span>{user.title_highlight}</span><br /> {user.title_head}</h6>
                </div>
            )

        })

    }, [projects])



    return (
        <div>
            <Nav />
            <div className='allProjectsSection'>
                <div className="mt40 pb150">
                    <div className="container projectGrid mb50" >
                        {Users}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default AllProjects