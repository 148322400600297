import { useEffect, useState } from 'react'
import { getVideos } from '../api'

const useVideo = () => {
  
    const [videos, setVideos] = useState()

    const getData = async(setFunc) => {
        const response = await getVideos()

        setFunc(response.data)
       
    }

    useEffect(()=>{
        getData(setVideos)
       
    }, [])

    return [videos]
}

export default useVideo