import axios from 'axios'

const axiosInstance = axios.create({baseURL: "https://api.techman-pro.com/"})


const getProjects = () => {
    return axiosInstance.get(`projects/all?lang=en`)
}

const getOneProject = () => {
    return axiosInstance.get(`projects/${localStorage.getItem('one_id')}?lang=en`)
}

const getVideos = () => {
    return axiosInstance.get("main_video/all")
}

const postFollower = (data) => {
    return axiosInstance.post("follow_us/follow", data)
}
const postEmail = (form) => {
    return axiosInstance.post("lets_talk/send-email", form)
}

export   {
    getProjects,
    getOneProject,
    getVideos,
    postEmail,
    postFollower

} 