import "./styles/home.css"
import "./styles/media.css"
import { useState } from 'react';
import anima from '../../assets/img/animation.gif'
import { Insta, Facebook, YouTube, Scroll } from "../../assets/svg"
import Video from "../VideoBox/Video/Video"


const Home = () => {

	const [isVideo, setVideo] = useState(false);
	const ShowVideoPopup = () =>{
		setVideo(!isVideo)
	}

	return (
		<div className='mainSection mt-150 ' id="home" >
			<div className="container">
				<div className="wrapper">
					<div className="rightBox z1 relative ">
						<h6>TECHMAN PRO SHOW TECHNOLOGIES</h6>
						<h1><span>YOUR PROFESSIONAL</span> SHOW  SOLUTIONS</h1>

					</div>
					<div className="leftbox releative">
						<div className="circleAnima" >
							<img className="headImg" src={anima} />
							<button className="showReel" onClick={ShowVideoPopup}>Show Reel</button>
						</div>
					</div>
					<div className="socialMediaBox">
						<h6 className="white">SOCIAL MEDIA</h6>
						<a href="https://www.instagram.com/techman_pro_show/" target="_blank"><Insta /></a>
						<a href="https://www.facebook.com/people/Techman-Pro-LLC/100069360424949/" target="_blank"><Facebook /></a>
						<a href="https://www.youtube.com/user/shirnepes" target="_blank"><YouTube /></a>
					</div>
					<div className="scroll-btn">
						<span className="scrollText">Scroll down</span>
						<Scroll />
					</div>
				</div>

				{isVideo && <Video handleClose={ShowVideoPopup}/> }

			</div>
		</div>
	)
}

export default Home