import './styles/footer.css'
import "./styles/media.css"
import logo from "../../assets/img/logo.png"
import { postFollower } from '../../utils/api';
import { useState } from 'react';

const Footer = () => {
	
	const [userEmail, setUserEmail] = useState('');

	const handleEmail = async () => {

		try {			
			await postFollower(new URLSearchParams({
				email: userEmail
			}))
			window.location.reload()
		}
		catch (error) {
			console.log(error);
		}
	}


	return (
		<div className='footer'>
			<div className="container">
				<div className="wrapper footerWrap">
					<div className="footerLinks">
						<img src={logo} />
						<p>© 2022. Tekmil - All right reserved.</p>
					</div>
					<div className="footerLinks mw200">
						<h6>SERVICES</h6>
						<a>Laser Shows</a>
						<a>Musical Fountains</a>
						<a>Video Projection</a>
						<a>Led Screen</a>
						<a>Trussing System</a>
						<a>Pro Lighting System</a>
						<a>Special Effects</a>
						<a>Pro Audio</a>
					</div>
					<div className="footerLinks">
						<h6>Links</h6>
						<a href="/">Home</a>
						<a href="/#who">Who we are</a>
						<a href="/#services">Services</a>
						<a href="all">Projects</a>
						<a href="/#contacts">Contacts</a>
					</div>

					<div className="footerLinks">
						<h6>Follow us</h6>

						<form onSubmit={(e) => { e.preventDefault() }}>
							<input type="mail" placeholder="E-mail" onChange={(e) => setUserEmail(e.target.value)} />
							<button className="followBtn" onClick={handleEmail} type="submit">Follow</button>
						</form>
					</div>
				</div>

			</div>
		</div>
	)
}

export default Footer